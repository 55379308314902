import { authGuard, permissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home-routing.module').then(m => m.HomeRoutingModule),
    canActivate: [authGuard],
  },
  {
    path: 'forums',
    loadChildren: () => import('./forums/forum-routing.module').then(m => m.ForumRoutingModule),
  },
  {
    path: 'message',
    loadChildren: () => import('./message/message-routing.module').then(m => m.MessageRoutingModule),
  },
  {
    path: 'forumevents',
    loadChildren: () => import('./forumevents/forum-events-routing.module').then(m => m.ForumEventsRoutingModule),
  },
  {
    path: 'availability',
    loadChildren: () => import('./availability-finder/availability-finder-routing.module').then(m => m.AvailabilityFinderRoutingModule),
  },

  {
    path: 'user-profile',
    loadChildren: () => import('./user-profile/user-profile-routing.module').then(m => m.UserProfileRoutingModule),
  },
  {
    path: 'updates',
    loadChildren: () =>
      import('./member-updates/member-updates-routing.module').then(m => m.MemberUpdatesRoutingModule),
  },

  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'gdpr',
    loadChildren: () => import('@volo/abp.ng.gdpr').then(m => m.GdprModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
  },

  {
    path: 'saas',
    loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy()),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then(m =>
        m.TextTemplateManagementModule.forLazy(),
      ),
  },
  {
    path: 'gdpr-cookie-consent',
    loadChildren: () =>
      import('./gdpr-cookie-consent/gdpr-cookie-consent-routing.module').then(m => m.GdprCookieConsentRoutingModule),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
