import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import { BrowserOptions } from '@sentry/angular';

if (environment.production) {
  enableProdMode();
}

const browserOptions: BrowserOptions = {
  dsn: 'https://219ca608d58dbeeb4c30fb267e3ff244@sentry.baytechconsulting.com/9',
  integrations: [],
  environment: `SPA-${environment.sentryEnvName}`,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  sampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
};

if (environment.production) {
  browserOptions.integrations = [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      maxReplayDuration: 120000,
      networkDetailAllowUrls: ['eosaas'],
    }),
  ];
  browserOptions.replaysOnErrorSampleRate = 1.0;
  browserOptions.replaysSessionSampleRate = 0.01;
}

Sentry.init(browserOptions);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

const originalWarn = console.warn;
console.warn = (...args) => {
  if (typeof args[0] === 'string' && args[0].includes('NG0955')) {
    return; // Suppress NG0955 warnings
  }
  if (typeof args[0] === 'string' && args[0].includes('Could not find localization source')) {
    return;
  }
  if (typeof args[0] === 'string' && args[0].includes('localization source')) {
    return;
  }
  originalWarn(...args);
};
